import React from 'react';

import {Helmet} from 'react-helmet';
import {Button, LinkButton} from 'src/components/controls';
import {Flex, LayoutGrid} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {HOME} from 'src/routes/paths';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {pageNotFound} from 'src/utils/copy';
import {HEADER_NAV_HEIGHT} from 'src/utils/measurements';
import {isSSR} from 'src/utils/ssr';
import styled from 'styled-components/macro';

const {H4, P2} = Typography;

const Circle = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  size: '80px',
  borderRadius: '1000px',
  border: '1px solid',
  borderColor: 'cream',
  color: 'cream',
  mb: '38px',
})``;

const StyledButton = styled(Button).attrs({
  width: ['100%', null, null, '169px'],
  maxWidth: '100%',
  border: '2px solid',
  borderRadius: '6.25rem',
  px: '1.875rem',
  py: '0.5625rem',
})`
  font-family: ${fonts.heading};
  font-weight: 800;
  background: none;
`;

const StyledLinkButton = styled(LinkButton).attrs({
  width: ['100%', null, null, '169px'],
  maxWidth: '100%',
  border: '2px solid',
  borderRadius: '6.25rem',
  px: '1.875rem',
  py: '0.5625rem',
})`
  font-family: ${fonts.heading};
  font-weight: 800;
`;

const NotFoundPage: React.FC = () => {
  const handleGoBack = () => {
    history.back();
  };

  const showGoBack = !isSSR && history.length > 1;

  return (
    <LayoutGrid
      height={HEADER_NAV_HEIGHT.responsive.map(navHeight =>
        navHeight !== null ? `calc(100vh - ${navHeight})}` : null,
      )}
      alignItems="center"
    >
      <Helmet>
        {/* Only used for Google Analytics error monitoring filter purpose */}
        <title>{pageNotFound.title}</title>
      </Helmet>
      <Flex gridColumn="1/end" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
        <Circle>
          <H4 color="cream" transform="translateY(1px)">
            {pageNotFound.title}
          </H4>
        </Circle>
        <H4 color="cream" mb="12px">
          {pageNotFound.heading}
        </H4>
        {showGoBack && <P2 color="lightGrey">{pageNotFound.subtitle}</P2>}
        <Flex width={['100%', null, null, 'auto']} flexDirection={['column', null, null, 'row']} mt="40px">
          {showGoBack && (
            <StyledButton
              variant="transparent"
              onClick={handleGoBack}
              mr={[null, null, null, '16px']}
              mb={['16px', null, null, '0px']}
            >
              {pageNotFound.backButton}
            </StyledButton>
          )}
          <StyledLinkButton variant="neonGreen" to={HOME}>
            {pageNotFound.homeButton}
          </StyledLinkButton>
        </Flex>
      </Flex>
    </LayoutGrid>
  );
};

export default NotFoundPage;
